.titreColor {
    color: #800b0054;
    font-weight: bold;
    
  }
.titreLabel {
    color: #800b00;
    font-weight: bold;
    
  }
  .text-danger{
    color :red;
    font-weight: bold;
  }